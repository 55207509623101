import React, { useState } from "react";
import Input from "../Input/Input";
import Textbox from "../Textbox/Textbox";
import styles from './ContactForm.module.css'

const ContactForm = ({ toggleContactModal, toggleNotificationModal, isNotified }) => {
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    name: "",
    message: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      const regex = /^[0-9\b]{0,10}$/;
      if (!regex.test(value)) {
        return;
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.email || (!isNotified && !formData.phone)) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }

    const formRequestData = isNotified
      ? {
          email: formData.email,
          message: "Notified",
        }
      : formData;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formRequestData),
    };

    fetch(
      "https://1oehkfx6ab.execute-api.ap-south-1.amazonaws.com/default/contactForm",
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          setSuccessMessage("Thanks! We will get back to you soon.");
          setFormData({
            email: "",
            phone: "",
            name: "",
            message: "",
          });
          // toggleContactModal(false);
        } else {
          setErrorMessage("Failed to submit form. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        setErrorMessage("Failed to submit form. Please try again later.");
      });
  };

  return (
    <div
      className={`contact-form-container ${isNotified ? {top: "10%"} : { top: 0 }}`}
    >
      <div className='inner-form-container py-5 bg-white w-2/3 absolute left-2/4'>
        <div className='flex items-baseline lg:items-center justify-between border-b pb-3 mx-8'>
          <h3 className='lg:text-3xl sm:text-2xl text-xl'>
            {isNotified ? "Get notified about our launch" : "Contact us"}
          </h3>
          <div
            className='close-icon cursor-pointer'
            onClick={() => {
              if (isNotified) {
                toggleNotificationModal(); 
              } else {
                toggleContactModal(); 
              }
            }}
          >
            <svg
              width='20'
              height='20'
              viewBox='0 0 14 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z'
                fill='black'
              />
            </svg>
          </div>
        </div>
        <form onSubmit={handleSubmit} className='px-8 pt-3'>
          {!isNotified && (
            <Input
              name='name'
              label='Name'
              type='text'
              className={styles.input}
              value={formData.name}
              onChange={(value) =>
                handleChange({ target: { name: "name", value } })
              }
            />
          )}
          <Input
            name='email'
            label='Email'
            type='email'
            className={styles.input}
            value={formData.email}
            onChange={(value) =>
              handleChange({ target: { name: "email", value } })
            }
            required={true}
          />
          {!isNotified && (
            <>
              <Input
                name='phone'
                label='Contact Number'
                type='tel'
                className={styles.input}
                value={formData.phone}
                onChange={(value) =>
                  handleChange({ target: { name: "phone", value } })
                }
                required={true}
              />
              <Textbox
                name='message'
                label='Message'
                type='text'
                className={styles.textBox}
                value={formData.message}
                onChange={(value) =>
                  handleChange({ target: { name: "message", value } })
                }
              />
            </>
          )}
          {errorMessage && (
            <div className=' text-sm text-red-600'>{errorMessage}</div>
          )}
          {successMessage && (
            <div className='mb-5 text-sm'>{successMessage}</div>
          )}
          <button type='submit' className='btn btn-secondary block ml-auto'>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
