import React from "react";

const Textbox = ({
  label,
  value,
  onChange,
  type,
  placeholder,
  className,
  required,
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <div className='input-group flex flex-col mb-5'>
      <label>{label}</label>
      <textarea
        type={type}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className={`${className} p-3`}
        required={required}
        rows={5}
      />
    </div>
  );
};

export default Textbox;
