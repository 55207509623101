import React from "react";

const Input = ({
  label,
  value,
  onChange,
  type,
  placeholder,
  className,
  required,
  pattern,
  maxLength = 12,
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <div className='input-group flex flex-col mb-5'>
      <label>
        {label} {required && <span className='asterisk text-red-500'>*</span>}
      </label>
      <input
        type={type}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className={`${className} border h-16 p-3`}
        required={required}
        pattern={pattern}
        maxLength={type === "tel" ? maxLength : null}
      />
    </div>
  );
};

export default Input;
