import { useState } from "react";
// import logo from "./assets/images/logo.png";
import logo1 from "./assets/images/logo1.jpg";
import section1 from "./assets/images/section1-image.png";
import section2 from "./assets/images/section2-image.png";
import section3 from "./assets/images/section3-image.png";
import section4 from "./assets/images/section4-image.png";
import section5 from "./assets/images/section5-image.png";
import "./App.css";
import ContactForm from "./components/ContactForm/ContactForm";
import { AnimatePresence, motion } from "framer-motion";

function App() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

  const toggleContactModal = () => {
    setIsContactModalOpen((prev) => !prev);
  };

  const toggleNotificationModal = () => {
    setIsNotificationModalOpen((prev) => !prev);
  };

  return (
    <div style={{
      overflow: `${isContactModalOpen ? "hidden" : "auto"}`,
      height: `${isContactModalOpen ? "100vh" : "auto"}`
    }}>
      <header>
        <AnimatePresence initial={false} onExitComplete={() => null}>
          {isContactModalOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="contactModal"
            >
              <ContactForm
                // modalClickHandler={toggleContactModal}
                toggleContactModal={toggleContactModal}
                isNotified={false}
                setIsNotified={() => { }}
              />
            </motion.div>
          )}

          {isNotificationModalOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="notificationModal"
            >
              <ContactForm
                // modalClickHandler={toggleNotificationModal}
                toggleNotificationModal={toggleNotificationModal}
                isNotified={true}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <nav className="navbar flex justify-between items-center">
          <div className="logo">
            <img src={logo1} alt="Captain Kitchens" style={{ width: "200px" }} />
          </div>
          <motion.button
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 1.2 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
            className="btn"
            onClick={toggleContactModal}
          >
            Contact Us
          </motion.button>
        </nav>
      </header>
      <main className="main-section flex flex-col-reverse lg:flex-row justify-between items-center py-14 lg:px-28 px-6">
        <div className="left flex-1">
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
            className="text-6xl md:text-7xl font-extrabold mb-5"
          >
            Launching soon!
          </motion.p>
          <motion.h1
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.1 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
          >
            Embracing Tradition: A Culinary Journey with Our Healthy Cloud
            Kitchen
          </motion.h1>
          <motion.button
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 1.2 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
            className="btn btn-secondary my-6"
            onClick={toggleNotificationModal}
          >
            Get notified
          </motion.button>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
          >
            At Captain Kitchens, we are dedicated to redefining the culinary
            experience by blending tradition, health, and convenience. Our
            mission is to provide our clients with access to wholesome,
            traditional meals that cater to their specific dietary needs and
            preferences. With a focus on serving fitness enthusiasts, we have
            partnered with a certified nutritionist to ensure that every dish we
            offer is not only delicious but also nutritionally balanced and
            informative.
          </motion.p>
        </div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 },
          }}
          className="right flex-1"
        >
          <img src={section1} alt="Captain Kitchens Image 1" />
        </motion.div>
      </main>

      {/* Section Two */}
      <section className="section-two lg:px-36 px-6 py-28 flex items-center gap-14 flex-wrap flex-col lg:flex-row">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.1 }}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: 100 },
          }}
          className="section-image flex-1"
        >
          <img src={section2} alt="Captain Kitchens Image 2" />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.1 }}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: -100 },
          }}
          className="section-text flex-1"
        >
          <div className="section-title relative">
            <h3 className="mb-8">Catering to Health-Conscious Individuals</h3>
          </div>
          <div className="section-description">
            <p>
              Our cloud kitchen takes pride in curating a menu that resonates
              with health-conscious individuals seeking nutritious and flavorful
              meal options. By collaborating with a certified nutritionist, we
              aim to empower our customers with valuable information about the
              calorie count, nutritional values, allergens, and substitute
              options present in our dishes. This approach is our commitment to
              transparency and ensuring that our patrons make informed choices
              about their meals.
            </p>
          </div>
        </motion.div>
      </section>

      {/* Section Three */}
      <section className="section-three lg:px-36 px-6 py-32 flex items-center gap-14 flex-wrap flex-col-reverse lg:flex-row">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.1 }}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: 100 },
          }}
          className="section-text flex-1"
        >
          <div className="section-title">
            <h3 className="mb-8">
              Quality Ingredients for Nourishing Creations
            </h3>
          </div>
          <div className="section-description">
            <p>
              Our cloud kitchen takes pride in curating a menu that resonates
              with health-conscious individuals seeking nutritious and flavorful
              meal options. By collaborating with a certified nutritionist, we
              aim to empower our customers with valuable information about the
              calorie count, nutritional values, allergens, and substitute
              options present in our dishes. This approach is our commitment to
              transparency and ensuring that our patrons make informed choices
              about their meals.
            </p>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.1 }}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: -100 },
          }}
          className="section-image flex-1"
        >
          <img src={section3} alt="Captain Kitchens Image 3" />
        </motion.div>
      </section>

      {/* Section Four */}
      <section className="section-four lg:px-36 px-6 py-32 flex items-center gap-14 flex-wrap flex-col lg:flex-row">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.1 }}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: 100 },
          }}
          className="section-image flex-1"
        >
          <img src={section4} alt="Captain Kitchens Image 4" />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.1 }}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: -100 },
          }}
          className="section-text flex-1"
        >
          <div className="section-title relative">
            <h3 className="mb-8">Embracing Tradition with Every Bite</h3>
          </div>
          <div className="section-description">
            <p>
              At Captain Kitchens, we celebrate the rich tapestry of traditional
              cuisine. Our culinary artisans draw inspiration from time-honored
              recipes and cooking techniques, infusing each dish with the
              essence of tradition. Whether it's a comforting dal prepared with
              care or a nourishing vegetable curry bursting with flavors, every
              meal reflects our dedication to preserving culinary heritage while
              meeting the demands of modern, health-conscious lifestyles.
            </p>
          </div>
        </motion.div>
      </section>

      {/* Section Five */}
      <section className="section-five lg:px-36 px-6 py-32 flex items-center gap-14 flex-wrap flex-col-reverse lg:flex-row">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.1 }}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: 100 },
          }}
          className="section-text flex-1"
        >
          <div className="section-title">
            <h3 className="mb-8">Convenience Redefined</h3>
          </div>
          <div className="section-description">
            <p>
              With our cloud kitchen's on-demand service, clients can savor the
              goodness of wholesome, traditional meals without compromising on
              convenience. Whether it's a busy workday or a leisurely evening at
              home, our clients can indulge in nutritious, chef-crafted dishes
              delivered directly to their doorstep, ensuring a seamless and
              satisfying dining experience.
            </p>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.1 }}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: -100 },
          }}
          className="section-image flex-1"
        >
          <img src={section5} alt="Captain Kitchens Image 5" />
        </motion.div>
      </section>

      <footer className='footer-section flex justify-center items-center py-4 text-white'>
        <p>© 2024  Captain Kitchens. All rights reserved </p>
      </footer>
    </div>
  );
}

export default App;
